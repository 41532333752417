#home_hero {
	background-image: url('../images/backgrounds/home_hero.jpg');
	background-size: cover;
	background-position: center;
	background-repeat: no-repeat;
	padding: 10em 1em;
	position: relative;


	&::before {
		height: 100%;
		width: 100%;
		background: rgba($gray_dark, .65);
		content: "";
		top: 0;
		left: 0;
		position: absolute;
	}

	&::after {
		height: 100%;
		width: 100%;
		background: linear-gradient(to bottom right, transparent 49.8%, rgba($gray_dark, .5) 50.2%);
		content: "";
		top: 0;
		left: 0;
		position: absolute;
	}
}

#home_hero_content {
	max-width: 1100px;
	margin: 0 auto;
	text-align: center;
	color: #fff;
	line-height: 1.5;
	position: relative;
	z-index: 1;
	padding: 2em 4em;
	box-sizing: border-box;

	&::before, &::after {
		height: 100%;
		width: 100px;
		position: absolute;
		content: "";
		top: 0;
	}

	&::before {
		left: 0;
		border: 3px solid #fff;
		border-right: none;
	}

	&::after {
		right: 0;
		border: 3px solid #fff;
		border-left: none;
	}

	h1 {
		font-family: $font_head;
		font-size: 54px;
		font-weight: 700;
	}

	h2 {
		font-size: 30px;
	}
}

#home_services {
	background: linear-gradient(to bottom, $gray_light 50%, #fff 50%);
	padding: 4em 1em;

	h3 {
		font-family: $font_head;
		font-size: 24px;
		font-weight: 700;
		color: $primary;
		text-align: center;
		margin-bottom: 1em;
	}

	h2 {
		text-align: center;
		font-size: 30px;
		max-width: 800px;
		line-height: 1.3;
		margin: 0 auto;
		margin-bottom: 4em;
	}
}

#home_services_boxes {
	max-width: 1200px;
	margin: 0 auto;
	display: flex;
}

.home_service_box {
	background: #fff;
	box-shadow: 0px 0px 10px 0px rgba(#000, .25);
	flex: 1;
	padding: 2em;
	display: flex;
	flex-direction: column;

	&[third_party] .home_service_box_img {
		background-image: url('../images/content/home_management.jpg');
	}

	&[consulting] .home_service_box_img {
		background-image: url('../images/content/home_consult.jpg');
	}

	&[development] .home_service_box_img {
		background-image: url('../images/content/home_development.jpg');
	}

	ul {
		list-style: disc outside;
		margin-top: 3em;
		margin-bottom: 2em;
		font-size: 18px;

		li {
			margin-left: 10px;
			line-height: 1.3;

			&+li {
				margin-top: 1em;
			}
		}
	}

	.btn {
		margin-top: auto;
		padding: 1em 0;
		text-align: center;
		font-size: 20px;
		font-weight: 700;
	}

	&+& {
		margin-left: 50px;
	}
}

.home_service_box_img {
	height: 280px;
	padding: 2em;
	box-sizing: border-box;
	background-size: cover;
	background-repeat: no-repeat;
	background-position: center;
	display: flex;
	justify-content: center;
	align-items: center;
	margin-top: -4em;
	position: relative;

	&::before {
		height: 100%;
		width: 100%;
		background: rgba($gray_dark, .5);
		top: 0;
		left: 0;
		content: "";
		position: absolute;
	}

	h4 {
		font-size: 24px;
		font-weight: 700;
		line-height: 1.3;
		color: #fff;
		text-align: center;
		font-family: $font_head;
		position: relative;
	}
}

#home_about {

	h3 {
		font-family: $font_head;
		font-size: 24px;
		font-weight: 700;
		color: $primary;
		text-align: center;
		margin-bottom: 1em;
	}

	h2 {
		text-align: center;
		font-size: 30px;
		max-width: 800px;
		line-height: 1.3;
		margin: 0 auto;
		margin-bottom: 2em;
	}
}

#home_about_img {
	background-image: url('../images/backgrounds/home_about.jpg');
	background-size: cover;
	background-repeat: no-repeat;
	background-position: center;
	padding: 5em 1em;
}

#home_about_text {
	max-width: 1200px;
	margin: 0 auto;

	p {
		max-width: 50%;
		margin-left: auto;
		font-size: 18px;
		line-height: 1.3;

		&+p {
			margin-top: 1.25em;
		}
	}
}

#home_reviews_wrap {
	background: linear-gradient(to bottom, #fff 50%, $gray_light 50%);
	padding: 5em 1em;
}

#home_reviews {
	max-width: 1200px;
	margin: 0 auto;
	box-shadow: 0px 0px 10px 0px rgba(#000, .25);
	background: #fff;
	display: flex;
	padding: 2em 1em;
	box-sizing: border-box;
	align-items: center;
}

#home_reviews_caro_inner {
	flex: 1;

	h3 {
		font-family: $font_head;
		font-size: 24px;
		font-weight: 700;
		color: $primary;
		text-align: center;
		margin: 1em 0;
	}

	h2 {
		text-align: center;
		font-size: 30px;
		max-width: 800px;
		line-height: 1.3;
		margin: 0 auto;
		margin-bottom: .5em;
	}
}

#home_reviews_caro {
	text-align: center;
	font-size: 18px;
	line-height: 1.4;

	.caro_inner {
		margin: 0;
	}

	.caro_slide {
		padding: 0 1em;
	}
}

#home_reviews_caro_controls {
	display: flex;
	justify-content: center;
	align-items: center;
	margin-top: .5em;

	button {
		margin: 0 2em;
		background: none;
		border: none;
		padding: 0;
		cursor: pointer;

		img {
			height: 75px;
			opacity: .5;
			transition: opacity .3s;
		}

		&:hover img {
			opacity: 1;
		}
	}
}

/***>>Media Queries***/
@media screen and (max-width: 1050px) {

	#home_hero_content {
		padding: 2em;

		h1 {
			font-size: 36px;
		}

		h2 {
			font-size: 24px;
		}
	}

	#home_reviews .accent_img {
		display: none;
	}
}

@media screen and (max-width: 1000px) {

	#home_services h2 {
		margin-bottom: 1em;
	}

	.home_service_box {
		padding: 1em;

		&+.home_service_box {
			margin-left: 1em;
		}

		ul li {
			margin-left: 20px;
		}
	}


	.home_service_box_img {
		height: 180px;
		margin-top: 0;
	}

	#home_about_img {
		padding: 1em;
	}

	#home_about_text {
		background: rgba(#fff, .75);
		padding: 1.5em;

		p {
			max-width: none;
		}
	}
}

@media screen and (max-width: 800px) {

	#home_services_boxes {
		flex-direction: column;
	}

	.home_service_box {

		.btn {
			max-width: 300px;
			width: 100%;
			align-self: center;
		}

		&+.home_service_box {
			margin: 1em 0 0 0;
		}
	}

	.home_service_box_img {
		height: 200px;
		max-width: 300px;
		align-self: center;
	}
}

@media screen and (max-width: 750px) {

	#home_hero {
		padding: 5em 1em;
	}

	#home_services, #home_about, #home_reviews_caro_inner {

		h2 {
			font-size: 24px;
		}
	}

	#home_services {
		padding: 1.5em 1em;
	}

	#home_reviews_wrap, #home_reviews {
		padding: 1em;
	}
}

@media screen and (max-width: 670px) {

	#home_hero_content {
		padding: 1em;

		h1 {
			font-size: 24px;
		}

		h2 {
			font-size: 18px;
		}
	}
}

@media screen and (max-width: 550px) {

	#home_reviews_caro {
		font-size: 14px;
	}
}

@media screen and (max-width: 500px) {

	#home_hero {
		padding: 3em 1em;
	}

	#home_hero_content {
		padding: 1em .5em;

		h1 {
			font-size: 20px;
		}

		h2 {
			font-size: 14px;
		}
	}
}
#privacy .internal_header {
	background-image: url('../images/backgrounds/privacy_policy_header.jpg');
}

#accessibility, #privacy {

	h3 {
		font-size: 20px;
		font-weight: 700;
		color: $primary;
	}

	p {

		&+p {
			margin-top: 1em;
		}

		&+h3 {
			margin-top: 1.5em;
		}
	}

	ul {
		margin-top: 1em;
		list-style: disc outside;

		li {
			margin-left: 25px;
		}

		&+h3 {
			margin-top: 1.5em;
		}
	}

	a {
		color: $primary;
	}
}

/***>>>Media Queries***/
@media screen and (max-width: 450px) {

	#privacy {

		a {
			word-break: break-all;
		}
	}
}
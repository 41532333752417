/*********************
* Fonts
*********************/
@font-face {
	font-family: 'Open Sans';
	font-style: normal;
	font-weight: 400;
	font-display: swap;
	src: url(https://fonts.gstatic.com/s/opensans/v18/mem8YaGs126MiZpBA-UFVZ0b.woff2) format('woff2');
	unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}
@font-face {
	font-family: 'Open Sans';
	font-style: normal;
	font-weight: 700;
	font-display: swap;
	src: url(https://fonts.gstatic.com/s/opensans/v18/mem5YaGs126MiZpBA-UN7rgOUuhp.woff2) format('woff2');
	unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}

@font-face {
	font-family: 'Playfair Display';
	font-style: normal;
	font-weight: 700;
	font-display: swap;
	src: url(https://fonts.gstatic.com/s/playfairdisplay/v21/nuFvD-vYSZviVYUb_rj3ij__anPXJzDwcbmjWBN2PKeiunDXbtM.woff2) format('woff2');
	unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}


$font: "Open Sans", sans-serif;
$font_head: "Playfair Display", serif;

/*********************
* Colors
*********************/
$primary: #b01117;
$primary_alt: #840d11;

$gray_light: #f2f2f2;
$gray: #333;
$gray_dark: #1a1a1a;

/*********************
* Reusable Styles
*********************/
/*** ID ***/

body {
	font-size: 16px;
}

#container {
	font-size: 1em;
	font-family: $font;
	width: 100%;
	min-width: 320px;
	max-width: 100vw;
	min-height: 100vh;
	padding: 0;
	margin: 0;
	overflow: hidden;
	position: relative;
	display: flex;
	flex-direction: column;
	box-sizing: border-box;

	main {
		flex: 1 0 auto;
	}

	abbr {
		text-decoration: none;
	}
}

/*** Classes ***/

.btn {
	display: inline-block;
	position: relative;
	z-index: 1;
	background: linear-gradient(to bottom left, $primary 49.8%, $primary_alt 50.2%);
	color: #fff;
	text-decoration: none;

	&::before {
		height: 100%;
		width: 100%;
		left: 0;
		top: 0;
		background: linear-gradient(to bottom left, $gray 49.8%, $gray_dark 50.2%);
		position: absolute;
		content: "";
		z-index: -1;
		opacity: 0;
		transition: opacity .3s;
	}

	@media screen and (min-width: 751px) {

		&:hover::before {
			opacity: 1;
		}
	}
}
#footer {
	background: $gray_dark;
	text-align: center;

	ul {
		display: flex;
		justify-content: center;
		align-items: center;
		margin-left: auto;
		padding: 1.5em 0 0;

		li {
			padding: 0 1em;

			a {
				display: block;
				color: #ffffff;
				text-decoration: none;
				position: relative;
				padding: 0;

				&::before {
					width: 100%;
					height: 1px;
					bottom: -4px;
					left: 50%;
					transform: translateX(-50%) scaleX(.5);
					opacity: 0;
					position: absolute;
					content: "";
					background: #fff;
					transition: transform .3s, opacity .3s;
				}

				&:hover::before {
					transform: translateX(-50%) scaleX(1);
					opacity: 1;
				}
			}

			&+li {
				border-left: 1px solid #ccc;
			}
		}
	}

	.credit {
		color: #fff;
		font-size: 10px;
		text-decoration: none;
		text-align: center;
		display: inline-block;
		padding: 2em 0;

		span {
			display: block;
			margin-bottom: .5em;
		}
	}
}

/***>>Media Queries***/

#skip_nav {
	position: fixed;
	left: -9999px;
	top: -9999px;
}

#header {
	padding: 1em 1.5em;
	position: relative;
	z-index: 999;
	display: flex;
	justify-content: space-between;
	box-shadow: 0px 0px 5px 0px rgba(#000, .25);
}

#header_left {
	position: relative;
}

#toggle_mobile_nav {
	display: block;
	position: absolute;
	height: 50px;
	width: 50px;
	padding: 0;
	margin: 0;
	background: $primary;
	top: 50%;
	right: 20px;
	transform: translateY(-50%);
	border: none;

	&::before, &::after {
		height: 10px;
		width: 100%;
		background: #fff;
		margin: 10px 0;
		content: "";
		display: block;
		transition: transform .3s;
	}

	&[state="open"] {

		&::before {
			transform: translateY(10px) rotate(45deg);
		}

		&::after {
			transform: translateY(-10px) rotate(-45deg);
		}
	}

	@media screen and (min-width: 751px) {
		display: none;
	}
}

#header_phone_mobile {
	display: block;
	position: absolute;
	height: 50px;
	width: 50px;
	padding: 0;
	margin: 0;
	top: 50%;
	left: 20px;
	transform: translateY(-50%);
	border: none;

	img {
		height: 100%;
	}

	@media screen and (min-width: 751px) {
		display: none;
	}
}

#header_right {
	display: flex;
	flex-direction: column;
	align-items: flex-end;
	justify-content: space-between;
}

#header_phone {
	color: $primary;
	font-weight: 700;
	font-size: 18px;

	a {
		color: $primary;
		text-decoration: none;

		&:hover {
			text-decoration: underline;
		}
	}
}

#nav {
	display: flex;

	>li {
		position: relative;

		>a, >span {
			position: relative;
			color: $primary;
			text-decoration: none;
			display: block;
			padding: .6em 0;

			&::before {
				width: 100%;
				height: 2px;
				bottom: 2px;
				left: 50%;
				transform: translateX(-50%) scaleX(.5);
				opacity: 0;
				position: absolute;
				content: "";
				background: $primary;
				transition: transform .3s, opacity .3s;
			}
		}

		@media screen and (min-width: 751px) {

			&[mobile_only] {
				display: none;
			}

			&:hover {

				>a, >span {

					&::before {
						transform: translateX(-50%) scaleX(1);
						opacity: 1;
					}
				}

				ul {
					opacity: 1;
					pointer-events: all;
				}
			}

			&:focus-within {

				>a, >span {

					&::before {
						transform: translateX(-50%) scaleX(1);
						opacity: 1;
					}
				}

				>span::after {
					display: block;
				}

				ul {
					opacity: 1;
					pointer-events: all;
				}
			}

			ul {
				position: absolute;
				top: 100%;
				background: #fff;
				white-space: nowrap;
				left: 50%;
				transform: translateX(-50%);
				z-index: -1;
				text-align: center;
				opacity: 0;
				transition: opacity .3s;
				pointer-events: none;
				padding: .5em 0;

				li {

					a {
						color: $primary;
						display: block;
						padding: .5em 1em;
						text-decoration: none;
						transition: background .3s, color .3s;

						&:hover {
							color: #fff;
							background: $primary;
						}
					}
				}
			}
		}

		&+li {
			margin-left: 3em;
		}
	}
}

/***>>Media Queries***/
@media screen and (max-width: 1050px) {

	#nav>li+li {
		margin-left: 1em;
	}
}

@media screen and (max-width: 910px) {

	#header_left .logo {
		max-width: 275px;
	}
}

@media screen and (max-width: 800px) {

	#nav {
		font-size: 14px;
	}
}

@media screen and (max-width: 750px) {

	#header {
		flex-direction: column;
		padding: 0;
	}

	#header_left {
		text-align: center;
		padding: .5em;

		.logo {
			max-width: 60%;
		}
	}

	#header_right {
		display: block;
	}

	#header_phone {
		display: none;
	}

	#nav {
		font-size: 16px;
		flex-direction: column;
		text-align: center;
		background: $primary;
		max-height: 0;
		overflow: hidden;
		transition: max-height .3s;

		&[state="open"] {
			max-height: 350px;
		}

		>li {

			>span {
				display: none;
			}

			>a {
				color: #fff;
				padding: .6em 0;
			}

			ul {

				li a {
					display: block;
					color: #fff;
					text-decoration: none;
					padding: .6em 0;
				}
			}

			&+li {
				margin-left: 0;
			}
		}
	}
}
.internal_header {
	font-family: $font_head;
	color: #fff;
	font-size: 54px;
	font-weight: 700;
	padding: 2em 1em;
	background-image: url('../images/backgrounds/accessibility_header.jpg');
	background-size: cover;
	background-repeat: no-repeat;
	background-position: center;
	text-align: center;
	position: relative;

	h1 {
		display: inline-block;
		padding-bottom: 8px;
		border-bottom: 3px solid $primary;
		position: relative;
		z-index: 5;
	}

	&::before {
		height: 100%;
		width: 100%;
		background: rgba($gray_dark, .65);
		content: "";
		top: 0;
		left: 0;
		position: absolute;
	}

	&::after {
		height: 100%;
		width: 100%;
		background: linear-gradient(to bottom right, transparent 49.8%, rgba($gray_dark, .5) 50.2%);
		content: "";
		top: 0;
		left: 0;
		position: absolute;
	}
}

.internal_content {

	h2 {
		font-family: $font_head;
		text-align: center;
		font-size: 24px;
		font-weight: 700;
		color: $primary;
		padding: 75px 1em 0;
	}
}

.internal_box_wrap {
	background: linear-gradient(to bottom, #fff 50%, $gray_light 50%);
	padding: 75px .5em;

	&.inverted {
		background: linear-gradient(to top, #fff 50%, $gray_light 50%);
	}
}

.internal_box {
	padding: 1.5em;
	box-shadow: 0px 0px 10px 0px rgba(#000, .25);
	max-width: 1200px;
	margin: 0 auto;
	box-sizing: border-box;
	background: #fff;
	font-size: 18px;
	line-height: 1.4;

	p+p {
		margin-top: 1em;
	}
}

#error .internal_box {
	text-align: center;
	min-height: 200px;

	.btn {
		padding: .75em 3em;
		font-weight: 700;
		margin-top: 2em;
	}
}

.about_member_wrap {

	&:nth-of-type(2n+1) {
		background: $gray_light;
	}
}

.about_member {
	max-width: 1200px;
	padding: 3em 1em;
	box-sizing: border-box;
	margin: 0 auto;
	display: flex;
	align-items: flex-start;

	img {
		max-width: 40%;
	}
}

.about_member_text {
	margin-left: 2em;
	line-height: 1.3;
	font-size: 18px;

	h4 {
		font-size: 20px;
		font-weight: 700;
		color: $primary;
	}

	h5 {
		font-style: italic;
		margin-bottom: 1.5em;
	}

	p+p {
		margin-top: 1em;
	}
}

#facilities {

	.internal_header {
		background-image: url('../images/backgrounds/facility_header.jpg');
	}
}

#facilities_items {
	padding: 3em 2em;
	max-width: 1200px;
	box-sizing: border-box;
	margin: 0 auto;
	columns: 2;
	column-gap: 1.5em;
}

.facility_item {
	break-inside: avoid;
	border: 1px solid $gray_light;
	margin-bottom: 1.5em;
	padding: 1em;
	display: flex;
}

.facility_details {
	align-self: center;
	margin-left: 1em;
	font-size: 18px;
	line-height: 1.2;

	a:hover {
		text-decoration: underline;
	}

	.title {
		color: $primary;
		font-size: 20px;
		font-weight: 700;
		text-decoration: none;
	}

	.phone {
		color: #000;
		text-decoration: none;
	}
}

#third_party .internal_header {
	background-image: url('../images/backgrounds/management_header.jpg');
}

#internal_info {
	max-width: 1200px;
	padding: 2em 1em;
	box-sizing: border-box;
	margin: 0 auto;
	display: flex;
	align-items: flex-start;
}

#internal_info_details {
	margin-left: 2em;
	line-height: 1.2;

	h4 {
		font-size: 20px;
		font-weight: 700;
		color: $primary;
	}

	p {
		font-size: 18px;

		&+p {
			margin-top: 1em;
		}

		&+h4 {
			margin-top: 1.5em;
		}
	}
}

#third_party_wrapup {
	background: $gray_light;
	line-height: 1.4;
	font-size: 18px;
	padding: 2em;

	p {
		max-width: 925px;
		text-align: center;
		margin: 0 auto;

		&+p {
			margin-top: 1.25em;
		}
	}
}

#consulting .internal_header {
	background-image: url('../images/backgrounds/consulting_header.jpg');
}

#consulting_projects {
	background: $gray_light;
}

#consulting_projects_inner {
	max-width: 1200px;
	margin: 0 auto;
	padding: 75px 1em 1em;
	display: flex;
	align-items: flex-start;
	flex-wrap: wrap;
}

.consulting_project {
	flex-basis: 50%;
	display: flex;
	align-items: center;
	line-height: 1.4;
	font-size: 18px;
	padding: 0 1em;
	box-sizing: border-box;
	margin-bottom: 3em;
}

.consulting_project_details {
	margin-left: 1em;

	h4 {
		font-size: 20px;
		font-weight: 700;
		color: $primary;

		a {
			color: $primary;
			text-decoration: underline;

			&:hover {
				text-decoration: none;
			}
		}
	}
}

#development .internal_header {
	background-image: url('../images/backgrounds/consulting_header.jpg');
}

#development_projects {
	background: $gray_light;
}

#development_projects_inner {
	max-width: 850px;
	margin: 0 auto;
	padding: 75px 1em 1em;
	display: flex;
	align-items: flex-start;
	flex-direction: column;
}

.development_project {
	width: 100%;
	// display: flex;
	// align-items: center;
	line-height: 1.4;
	font-size: 18px;
	padding: 0 1em;
	box-sizing: border-box;
	margin-bottom: 3em;
}

.development_project_details {
	margin-left: 1em;

	h4 {
		font-size: 20px;
		font-weight: 700;
		color: $primary;
	}

	p {
		width: 100%;
	}
}

/***>>Media Queries***/


@media screen and (max-width: 1050px) {

	.facility_item {
		flex-direction: column;
		align-items: flex-start;
	}

	.facility_details {
		align-self: normal;
		margin: 1em 0 0 0;
	}
}

@media screen and (max-width: 1000px) {

	#internal_info img {
		max-width: 50%;
	}
}

@media screen and (max-width: 800px) {

	#consulting_projects_inner {
		flex-wrap: nowrap;
		flex-direction: column;
	}

	.consulting_project {
		flex-basis: 100%;
		padding: 0;
	}

	#development_projects_inner {
		flex-wrap: nowrap;
		flex-direction: column;
	}

	.development_project {
		flex-basis: 100%;
		padding: 0;
	}

	.about_member {
		flex-direction: column-reverse;
		padding: 1em;

		img {
			width: 100%;
			max-width: 500px;
			margin: 0 auto;
		}
	}

	.about_member_text {
		margin: 0 0 1em 0;
	}
}

@media screen and (max-width: 750px) {

	.internal_header {
		font-size: 36px;
		padding: 1em;
	}

	.internal_content h2 {
		padding-top: 24px;
	}

	.internal_box_wrap {
		padding: 24px .5em;
	}

	#internal_info {
		flex-direction: column-reverse;

		img {
			display: none;
		}
	}
}

@media screen and (max-width: 700px) {

	#facilities_items {
		padding: 1em;
		columns: 1;
	}

	.facility_item {
		align-items: center;
		text-align: center;
	}

	#internal_info_details {
		margin: 0 0 2em 0;
	}

	#consulting_projects_inner {
		padding-top: 24px;
	}

	#development_projects_inner {
		padding-top: 24px;
	}
}
#contact {

	.internal_header {
		background-image: url('../images/backgrounds/contact_header.jpg');
	}

	.internal_box {
		min-height: 0;
	}
}

#contact_form_wrap {
	padding: 2em 1em;
	max-width: 1200px;
	margin: 0 auto;
	box-sizing: border-box;
}

#contact_form {
	display: flex;
	flex-wrap: wrap;

	label, .hp_wrap {
		position: fixed;
		left: -9999px;
	}

	input, textarea {
		display: block;
		margin: 1em 0;
		width: 100%;
		font-size: 18px;
		font-family: $font;
		background: $gray_light;
		border: 1px solid darken($gray_light, 5);
		padding: 1em;
		box-sizing: border-box;

		&.error {
			border-color: $primary_alt;
		}
	}

	textarea {
		height: calc(100% - 2em);
	}
}

.contact_form_left, .contact_form_right {
	flex: 1 0 50%;
	padding: .5em;
	box-sizing: border-box;
}

.contact_form_bottom {
	flex-basis: 100%;
	text-align: center;

	.response_message {
		margin: 0 0 1em 0;

		span {
			display: inline-block;
			padding: 1em;
		}

		.error {
			background: rgba($primary_alt, .5);
			border: 1px solid $primary_alt;
		}

		.success {
			background: rgba(#116611, .5);
			border: 1px solid #116611;
		}
	}

	.btn {
		cursor: pointer;
		font-family: $font;
		font-size: 20px;
		font-weight: 700;
		padding: .75em 6em;
		border: none;
	}
}

/***>>>Media Queries***/
@media screen and (max-width: 600px) {

	#contact_form {
		flex-wrap: nowrap;
		flex-direction: column;

		textarea {
			margin-top: 0;
			height: 100px;
		}
	}

	.contact_form_left, .contact_form_right {
		flex: initial;
		padding: 0;
	}
}